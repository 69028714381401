 @import '@angular/material/theming';
 @include mat-core();
// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
// :root {
//   /** primary **/
//   --ion-color-primary: #207daf;
//   --ion-color-primary-rgb: 28,28,28;
//   --ion-color-primary-contrast: #ffffff;
//   --ion-color-primary-contrast-rgb: 255,255,255;
//   --ion-color-primary-shade: #191919;
//   --ion-color-primary-tint: #333333;

//   /** secondary **/
//   --ion-color-secondary: #ffd20a;
//   --ion-color-secondary-rgb: 255,0,98;
//   --ion-color-secondary-contrast: #ffffff;
//   --ion-color-secondary-contrast-rgb: 255,255,255;
//   --ion-color-secondary-shade: #e00056;
//   --ion-color-secondary-tint: #ff1a72;

//   /** tertiary **/
//   --ion-color-tertiary: #00AFFF;
//   --ion-color-tertiary-rgb: 0,175,255;
//   --ion-color-tertiary-contrast: #000000;
//   --ion-color-tertiary-contrast-rgb: 0,0,0;
//   --ion-color-tertiary-shade: #009ae0;
//   --ion-color-tertiary-tint: #1ab7ff;

//   /** success **/
//   --ion-color-success: #20dc6a;
//   --ion-color-success-rgb: 32,220,106;
//   --ion-color-success-contrast: #000000;
//   --ion-color-success-contrast-rgb: 0,0,0;
//   --ion-color-success-shade: #1cc25d;
//   --ion-color-success-tint: #36e079;

//   /** warning **/
//   --ion-color-warning: #ffed11;
//   --ion-color-warning-rgb: 255,237,17;
//   --ion-color-warning-contrast: #000000;
//   --ion-color-warning-contrast-rgb: 0,0,0;
//   --ion-color-warning-shade: #e0d10f;
//   --ion-color-warning-tint: #ffef29;

//   /** danger **/
//   --ion-color-danger: #f4344f;
//   --ion-color-danger-rgb: 244,52,79;
//   --ion-color-danger-contrast: #ffffff;
//   --ion-color-danger-contrast-rgb: 255,255,255;
//   --ion-color-danger-shade: #d72e46;
//   --ion-color-danger-tint: #f54861;

//   /** lightest **/
//   --ion-color-lightest: #FFFFFF;
//   --ion-color-lightest-rgb: 255,255,255;

//   /** light **/
//   --ion-color-light: #f4f5f8;
//   --ion-color-light-rgb: 244,244,244;
//   --ion-color-light-contrast: #000000;
//   --ion-color-light-contrast-rgb: 0,0,0;
//   --ion-color-light-shade: #d7d8da;
//   --ion-color-light-shade-rgb: 215,216,218;
//   --ion-color-light-tint: #f5f6f9;
//   --ion-color-light-tint-rgb: 245,246,249;

//   /** medium **/
//   --ion-color-medium: #989aa2;
//   --ion-color-medium-rgb: 152,154,162;
//   --ion-color-medium-contrast: #ffffff;
//   --ion-color-medium-contrast-rgb: 255,255,255;
//   --ion-color-medium-shade: #86888f;
//   --ion-color-medium-shade-rgb: 134,136,143;
//   --ion-color-medium-tint: #a2a4ab;
//   --ion-color-medium-tint-rgb: 162,164,171;

//   /** dark **/
//   --ion-color-dark: #222428;
//   --ion-color-dark-rgb: 34,34,34;
//   --ion-color-dark-contrast: #ffffff;
//   --ion-color-dark-contrast-rgb: 255,255,255;
//   --ion-color-dark-shade: #1e2023;
//   --ion-color-dark-shade-rgb: 30,32,35;
//   --ion-color-dark-tint: #383a3e;
//   --ion-color-dark-tint-rgb: 56,58,62;

//   /** darkest **/
//   --ion-color-darkest: #000000;
//   --ion-color-darkest-rgb: 0,0,0;
// }
/* For use in src/lib/core/theming/_palette.scss */
$md-oceanablue: (
    50 : #e4eff5,
    100 : #bcd8e7,
    200 : #90bed7,
    300 : #63a4c7,
    400 : #4191bb,
    500 : #207daf,
    600 : #1c75a8,
    700 : #186a9f,
    800 : #136096,
    900 : #0b4d86,
    A100 : #b6daff,
    A200 : #83c0ff,
    A400 : #50a6ff,
    A700 : #3699ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);



.nzeo-theme {
     $oceana-theme-primary: mat-palette($md-oceanablue, 400);
     $oceana-theme-accent:  mat-palette($md-oceanablue, 400);

     $oceana-theme: mat-light-theme($oceana-theme-primary, $oceana-theme-accent);

     @include angular-material-theme($oceana-theme);
 }

:root {
	--ion-color-primary: #207daf;
	--ion-color-primary-rgb: 32,125,175;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #1c6e9a;
	--ion-color-primary-tint: #368ab7;

	--ion-color-secondary: #ffd20a;
	--ion-color-secondary-rgb: 255,210,10;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0,0,0;
	--ion-color-secondary-shade: #e0b909;
	--ion-color-secondary-tint: #ffd723;

	--ion-color-tertiary: #003E71;
	--ion-color-tertiary-rgb: 0,62,113;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #003763;
	--ion-color-tertiary-tint: #1a517f;

	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45,211,111;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255,196,9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235,68,90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146,148,156;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0,0,0;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244,245,248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;

}

:root {
  /* Set the font family of the entire app */
  --ion-font-family: 'Rubik', sans-serif;

  --app-background: #FFFFFF;
  --app-background-shade: var(--ion-background-color-step-50, #F2F2F2);
  --app-background-alt: var(--ion-color-primary);
  --app-background-alt-shade: var(--ion-color-primary-shade);

  --app-narrow-margin: 12px;
  --app-fair-margin: 16px;
  --app-broad-margin: 20px;

  --app-narrow-radius: 4px;
  --app-fair-radius: 8px;
  --app-broad-radius: 12px;
}

html.ios {
  --app-header-height: 44px;
}

html.md {
  --app-header-height: 56px;
}
