// http://ionicframework.com/docs/theming/
@import url("https://fonts.googleapis.com/css?family=Rubik:300,400,500,700");

@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "swiper/scss";
@import "swiper/scss/autoplay";
@import "swiper/scss/pagination";
@import "@ionic/angular/css/ionic-swiper";

// EXTRA GLOBAL STYLES
// Add custom Ionic Colors
@import "theme/custom-ion-colors.scss";
// Add base app styles
@import "theme/app-defaults.scss";
// Add base shell styles
@import "theme/shell-defaults.scss";

@import "@angular/material/prebuilt-themes/deeppurple-amber.css";


/* ===============
   FORM SETTINGS 
   =============== */

formly-form {
  display: block;
  text-align: center;
}

ion-select {
  max-width: 95% !important;
}

ion-input {
 text-align: right !important;
}

  .matrix-header {
    display: none;
  }
  .display-flex {
    display: grid;
    flex: 6;
    grid-template-columns: auto;
    text-align: center;
  }

.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-5 {
  flex: 5;
}
.flex-6 {
  flex: 6;
}



